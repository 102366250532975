import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Home,
  Building2,
  Factory,
  Hotel,
  Store,
  Church,
  PencilRuler,
  Ruler,
  HardHat,
  PaintBucket,
  Wrench,
  FileText,
  Send,
  Sparkles
} from 'lucide-react';

const projectTypes = [
  { icon: Home, label: 'Residential' },
  { icon: Building2, label: 'Commercial' },
  { icon: Factory, label: 'Industrial' },
  { icon: Hotel, label: 'Hospitality' },
  { icon: Store, label: 'Retail' },
  { icon: Church, label: 'Institutional' }
];

const services = [
  { icon: PencilRuler, label: 'Architectural Design' },
  { icon: Ruler, label: 'Engineering' },
  { icon: HardHat, label: 'Construction' },
  { icon: PaintBucket, label: 'Interior Design' },
  { icon: Wrench, label: 'Renovation' },
  { icon: FileText, label: 'Planning & Permits' }
];

export const QuotationForm = function() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    projectType: '',
    requiredServices: [],
    propertySize: '',
    budget: '',
    timeframe: '',
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleServiceToggle = (service) => {
    setFormData(prev => ({
      ...prev,
      requiredServices: prev.requiredServices.includes(service)
        ? prev.requiredServices.filter(s => s !== service)
        : [...prev.requiredServices, service]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Create an object with all the form data
    const submissionData = {
      ...formData,
      requiredServices: formData.requiredServices.join(', ') // Convert array to string
    };
    
    // Convert the object to FormData
    const formDataToSend = new FormData();
    Object.keys(submissionData).forEach(key => {
      formDataToSend.append(key, submissionData[key]);
    });
    
    try {
      const response = await fetch("https://formspree.io/f/xldeebzz", {
        method: "POST",
        body: formDataToSend,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.ok) {
        setFormStatus("Thanks for your submission!");
        setFormData({
          projectType: '',
          requiredServices: [],
          propertySize: '',
          budget: '',
          timeframe: '',
          name: '',
          email: '',
          phone: '',
          message: ''
        });
        setCurrentStep(1);
      } else {
        setFormStatus("Oops! There was a problem submitting your form");
      }
    } catch (error) {
      setFormStatus("Oops! There was a problem submitting your form");
    }
  };

  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, 4));
  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 1));

  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h3 className="text-xl font-bold mb-4">Project Type</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {projectTypes.map(({ icon: Icon, label }) => (
                <motion.button
                  key={label}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={() => setFormData(prev => ({ ...prev, projectType: label }))}
                  className={`p-6 rounded-xl flex flex-col items-center justify-center space-y-3 transition-all duration-300 ${
                    formData.projectType === label 
                      ? 'bg-gradient-to-br from-blue-500 to-purple-600 text-white shadow-lg' 
                      : 'bg-gray-100 hover:bg-gray-200'
                  }`}
                >
                  <Icon size={32} className={formData.projectType === label ? 'text-white' : 'text-gray-600'} />
                  <span className="font-medium">{label}</span>
                </motion.button>
              ))}
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h3 className="text-xl font-bold mb-4">Services Required</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {services.map(({ icon: Icon, label }) => (
                <motion.button
                  key={label}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={() => handleServiceToggle(label)}
                  className={`p-6 rounded-xl flex flex-col items-center justify-center space-y-3 transition-all duration-300 ${
                    formData.requiredServices.includes(label)
                      ? 'bg-gradient-to-br from-blue-500 to-purple-600 text-white shadow-lg'
                      : 'bg-gray-100 hover:bg-gray-200'
                  }`}
                >
                  <Icon size={32} className={formData.requiredServices.includes(label) ? 'text-white' : 'text-gray-600'} />
                  <span className="font-medium">{label}</span>
                </motion.button>
              ))}
            </div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            className="space-y-6"
          >
            <h3 className="text-xl font-bold mb-4">Project Details</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="relative">
                <input
                  type="text"
                  id="propertySize"
                  name="propertySize"
                  value={formData.propertySize}
                  onChange={handleChange}
                  className="w-full p-4 bg-gray-100 rounded-xl outline-none transition-all duration-300 focus:ring-2 focus:ring-blue-500"
                  placeholder="Property Size (sqm)"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <span className="text-gray-500">m²</span>
                </div>
              </div>
              <div>
                <select
                  id="budget"
                  name="budget"
                  value={formData.budget}
                  onChange={handleChange}
                  className="w-full p-4 bg-gray-100 rounded-xl outline-none transition-all duration-300 focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select Budget Range</option>
                  <option value="0-100">Less than $100</option>
                  <option value="100-500">$100 - $500</option>
                  <option value="500-1000">$500 - $1000</option>
                  <option value="1000-5000">$1000 - $5000</option>
                  <option value="5000+">$5000+</option>
                </select>
              </div>
            </div>
            <div>
              <select
                id="timeframe"
                name="timeframe"
                value={formData.timeframe}
                onChange={handleChange}
                className="w-full p-4 bg-gray-100 rounded-xl outline-none transition-all duration-300 focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Timeframe</option>
                <option value="1-3months">1-3 months</option>
                <option value="3-6months">3-6 months</option>
                <option value="6-12months">6-12 months</option>
                <option value="12months+">More than 12 months</option>
              </select>
            </div>
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            className="space-y-6"
          >
            <h3 className="text-xl font-bold mb-4">Contact Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-4 bg-gray-100 rounded-xl outline-none transition-all duration-300 focus:ring-2 focus:ring-blue-500"
                placeholder="Your Name"
                required
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full p-4 bg-gray-100 rounded-xl outline-none transition-all duration-300 focus:ring-2 focus:ring-blue-500"
                placeholder="Phone Number"
                required
              />
            </div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-4 bg-gray-100 rounded-xl outline-none transition-all duration-300 focus:ring-2 focus:ring-blue-500"
              placeholder="Email Address"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              className="w-full p-4 bg-gray-100 rounded-xl outline-none transition-all duration-300 focus:ring-2 focus:ring-blue-500"
              placeholder="Additional Details..."
            ></textarea>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <motion.div
        className="bg-white rounded-2xl shadow-2xl p-8 relative overflow-hidden"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-blue-500/10 to-purple-600/10 rounded-full -mr-32 -mt-32 blur-3xl"></div>
        <div className="absolute bottom-0 left-0 w-64 h-64 bg-gradient-to-tr from-blue-500/10 to-purple-600/10 rounded-full -ml-32 -mb-32 blur-3xl"></div>
        
        <div className="relative">
          <div className="flex items-center justify-center mb-8">
            <Sparkles className="text-blue-500 mr-2" size={24} />
            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
              Design Your Future
            </h2>
          </div>

          <div className="mb-8">
            <div className="flex justify-between items-center">
              {[1, 2, 3, 4].map(step => (
                <div
                  key={step}
                  className={`flex-1 h-2 rounded-full mx-2 transition-all duration-500 ${
                    step <= currentStep ? 'bg-gradient-to-r from-blue-500 to-purple-600' : 'bg-gray-200'
                  }`}
                ></div>
              ))}
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {renderStep()}

            <div className="flex justify-between mt-8">
              {currentStep > 1 && (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={prevStep}
                  className="px-6 py-3 bg-gray-100 rounded-xl font-medium hover:bg-gray-200 transition-colors"
                >
                  Back
                </motion.button>
              )}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type={currentStep === 4 ? "submit" : "button"}
                onClick={currentStep < 4 ? nextStep : undefined}
                className="px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-xl font-medium hover:from-blue-600 hover:to-purple-700 transition-colors ml-auto"
              >
                {currentStep === 4 ? (
                  <span className="flex items-center">
                    Send Request <Send className="ml-2" size={18} />
                  </span>
                ) : "Next"}
              </motion.button>
            </div>
          </form>

          {formStatus && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-6 p-4 bg-gradient-to-r from-green-500 to-emerald-600 text-white rounded-xl text-center font-medium"
            >
              {formStatus}
            </motion.div>
          )}
        </div>
      </motion.div>
    </div>
  );
}

