import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Phone, Mail, MapPin, Send } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import bgimage from '../Assets/back.png';
import bgimage2 from '../Assets/back2.png';
import bgimage3 from '../Assets/back3.png';
import about from '../Assets/about.png';
import project1 from "../Assets/project1.png";
import magolis from "../Assets/margolis.png"
import vainona from "../Assets/Vainona.png";
import './styles/Home.css';
import { Helmet } from 'react-helmet';

export const Home = ({title}) => {
  const [currentBg, setCurrentBg] = useState(bgimage);
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const navigate = useNavigate();
  const [formStatus, setFormStatus] = useState('');

  const handleProjectClick = () => {
  navigate('/projects');
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    
    try {
      const response = await fetch("https://formspree.io/f/xvgooada", {
        method: "POST",
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.ok) {
        setFormStatus("Thanks for your submission!");
        form.reset();
      } else {
        setFormStatus("Oops! There was a problem submitting your form");
      }
    } catch (error) {
      setFormStatus("Oops! There was a problem submitting your form");
    }
  };

  const bgImages = useMemo(() => [bgimage, bgimage2, bgimage3], []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [bgImages.length]);

  useEffect(() => {
    setCurrentBg(bgImages[currentBgIndex]);
  }, [currentBgIndex, bgImages]);

  const handleServices = () => {
    navigate("/services/services");
  };

  const projects = [
    {
      title: "MANSION FOR MR DHIHWA IN BORROWDALE",
      description: "An opulent mansion featuring cutting-edge architectural design and luxurious interiors, crafted to reflect elegance and comfort in the prestigious Borrowdale neighborhood.",
      image: project1,
    },
    {
      title: "STEPHEN MARGOLIS' SHOPPING MALL",
      description: "A dynamic and expansive shopping mall offering a blend of modern retail spaces, entertainment hubs, and sustainable design to create a top-tier shopping destination.",
      image: magolis,
    },
    {
      title: "VAINONA CLUSTERS",
      description: "A thoughtfully designed residential development offering a blend of contemporary homes and community-driven living, transforming Vainona into a vibrant, harmonious neighborhood.",
      image: vainona,
    },
  ];

  const handleClick = () => {
    navigate('/about_build_fast');
  };

  const handleServiceClick = (service) => {
    navigate(`/services/${service.toLowerCase().replace(' ', '-')}`);
  };

  const services = [
    {
      title: "Architecture",
      description: "We craft innovative and functional designs that harmonize with their surroundings and reflect our clients' visions.",
      image: "https://images.pexels.com/photos/1109541/pexels-photo-1109541.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      title: "Engineering",
      description: "Our engineering team integrates structural, mechanical, and electrical systems to ensure seamless functionality and safety.",
      image: "https://images.pexels.com/photos/33192/paddle-wheel-bucket-wheel-excavators-brown-coal-open-pit-mining.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      title: "Town Planning",
      description: "We offer expert town planning services to help shape the development of communities and urban spaces.",
      image: "https://images.pexels.com/photos/1131863/pexels-photo-1131863.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      title: "Interior Design",
      description: "Our interior design team creates spaces that are not only beautiful but also functional, enhancing the quality of life.",
      image: "https://images.pexels.com/photos/276267/pexels-photo-276267.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      title: "Project Management",
      description: "We manage all aspects of construction projects to ensure they are completed on time, within budget, and to the highest standards.",
      image: "https://images.pexels.com/photos/7376/startup-photos.jpg?auto=compress&cs=tinysrgb&w=600"
    }
  ];

  const pricingTiers = [
    {
      size: "200-300SQM",
      price: "175",
      features: [
        "Main House Plan",
        "Durawall (Boundary) Wall Plan",
        "Bonus: 3D Images"
      ]
    },
    {
      size: "350-500SQM",
      price: "315",
      features: [
        "Main House Plan",
        "Cottage Plan",
        "Durawall (Boundary) Wall Plan",
        "Approval Assistance",
        "Bonus: 3D Images"
      ]
    },
    {
      size: "500-1000SQM",
      price: "420",
      features: [
        "Main House Plan",
        "Cottage Plan",
        "Durawall (Boundary) Wall Plan",
        "Approval Assistance",
        "Bonus: 3D Images"
      ]
    },
    {
      size: "1000+SQM Single",
      price: "610",
      features: [
        "Single Storey Plan",
        "Cottage Plan",
        "Durawall (Boundary) Wall Plan",
        "Approval Assistance",
        "Bonus: 3D Images"
      ]
    },
    {
      size: "1000+SQM Double",
      price: "965",
      features: [
        "Double Storey Plan",
        "Cottage Plan",
        "Boundary Wall Plan",
        "Engineering Drawings",
        "Approval Assistance",
        "Bonus: 3D Images"
      ]
    }
  ];

  return (
    <main className="overflow-x-hidden gap-6">
      <Helmet>
        <meta name="google-site-verification" content="q2KMOxqP0WRZnd8BDIXv-m18BP7E8GapB0_6ZOX9pZM"  />
      </Helmet>
      {/* Background Image and Hero Section */}
      <section className="relative h-screen overflow-hidden">
        <AnimatePresence initial={false}>
          <motion.div
            key={currentBgIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${currentBg})` }}
          />
        </AnimatePresence>
        <div className="relative h-full flex items-center justify-center z-10">
          <div className="text-center text-gray-900">
            <motion.h1
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="text-2xl md:text-2xl font-bold mb-4"
            >
              Buildfast Structural Solutions
            </motion.h1>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-2xl md:text-3xl mb-8"
            >
              YOU DREAM IT, WE DESIGN IT, WE BUILD IT!
            </motion.p>
            <motion.button
              onClick={handleServices}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-lg font-medium text-gray-900 rounded-lg bg-gradient-to-br from-cyan-500 to-blue-500 hover:text-white group hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
            >
              <span className="relative px-6 py-3 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                See Our Services
                <ChevronRight className="inline-block ml-2" size={20} />
              </span>
            </motion.button>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="flex gap-4 flex-col md:flex-row justify-between items-center mt-4 py-4 px-6 bg-gray-100">
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="grid place-items-center  mb-8 md:mb-0"
        >
          <img className="rounded-lg m-4 shadow-xl" src={about} alt="about us" />
        </motion.div>
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-xl text-gray-700"
        >
          <h2 className="text-4xl font-bold mb-6 text-center md:text-left text-gray-900">About Us</h2>
          <p className="my-4 text-lg">
            At BuildFast Structural Solutions, we are dedicated to transforming visions into reality through innovative and sustainable design. Our experienced team is committed to delivering exceptional results that inspire and enhance the human experience.
          </p>
          <p className="my-4 text-lg">
            With a team of visionary architects and design professionals, we specialize in creating dynamic environments that blend functionality with aesthetic appeal. Our approach is rooted in a deep understanding of our clients' needs and aspirations, combined with a commitment to pushing the boundaries of conventional design.
          </p>
          <p className="my-4 text-lg">
            At BuildFast Structural Solutions, we believe that every project is an opportunity to make a positive impact. Whether designing residential, commercial, or civic spaces, our goal is to deliver solutions that are not only visually stunning but also environmentally responsible and future-focused.
          </p>
          <motion.button
            onClick={handleClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="mt-4 bg-yellow-400 hover:bg-yellow-500 text-blue-800 font-bold hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 rounded-lg text-lg px-6 py-3 text-center inline-flex items-center"
          >
            Learn More
            <ChevronRight className="ml-2" size={20} />
          </motion.button>
        </motion.div>
      </section>

      {/* Services Section */}
      <section className="py-4 mt-4 px-6 bg-white">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">Our Services</h2>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            loop={true}
          >
            {services.map((service, index) => (
            <SwiperSlide key={index}>
              <div 
                className="flex flex-col h-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 cursor-pointer"
                onClick={() => handleServiceClick(service.title)}
              >
                <img 
                  className="w-full h-48 object-cover rounded-t-lg" 
                  src={service.image} 
                  alt={service.title} 
                />
                <div className="flex flex-col justify-between p-4 leading-normal flex-grow">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {service.title}
                  </h5>
                  <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">
                    {service.description}
                  </p>
                  <div className="flex items-center text-blue-600 hover:underline mt-auto">
                    Learn More
                    <ChevronRight className="ml-1" size={16} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            ))}
          </Swiper>
        </motion.div>
      </section>

      {/* Projects Section - Updated with navigation */}
      <section className="py-4 mt-4 px-6 bg-gray-100">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">Recent Projects</h2>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            loop={true}
          >
          {projects.map((project, index) => (
            <SwiperSlide key={index}>
              <div 
                className="flex flex-col h-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 cursor-pointer transform transition-all duration-300 hover:scale-105"
                onClick={handleProjectClick}
              >
                <img 
                  className="object-cover w-full h-48 rounded-t-lg" 
                  src={project.image} 
                  alt={project.title}
                />
                <div className="flex flex-col justify-between p-4 leading-normal flex-grow">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {project.title}
                  </h5>
                  <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">
                    {project.description}
                  </p>
                  <div 
                    className="flex items-center text-blue-600 hover:underline mt-auto"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent double navigation
                      handleProjectClick();
                    }}
                  >
                    View Project
                    <ChevronRight className="ml-1" size={16} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          </Swiper>
        </motion.div>
      </section>
      {/* Products and Prices Section */}
      <section className="py-12 bg-white">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto px-4"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">Our Products and Prices</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {pricingTiers.map((tier, index) => (
              <motion.div
                key={tier.size}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-lg shadow-lg relative overflow-hidden border border-gray-200 hover:border-blue-500 transition-all duration-300"
              >
                <div className="absolute -right-10 top-5 rotate-45 bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-10 py-1">
                  ${tier.price}
                </div>
                <h3 className="text-xl font-bold mb-4 text-gray-900">{tier.size}</h3>
                <ul className="space-y-3 mb-6">
                  {tier.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start">
                      <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
                <motion.button 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    const contactForm = document.querySelector("#contact-form");
                    if (contactForm) {
                      contactForm.scrollIntoView({ behavior: "smooth" });
                    }
                    const messageInput = document.querySelector("#message");
                    if (messageInput) {
                      messageInput.value = `I'm interested in the ${tier.size} package priced at $${tier.price}. Please provide more information.`;
                    }
                  }}
                  className="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-semibold py-2 px-4 rounded hover:from-cyan-600 hover:to-blue-600 transition-all duration-300"
                >
                  Get Started
                </motion.button>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Contact Us Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-gray-100 to-white">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto h-full"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">
            Get In Touch
          </h2>
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Contact Form */}
            <motion.div 
              className="flex-1 bg-white rounded-lg shadow-xl p-8 border border-gray-200"
              whileHover={{ boxShadow: "0 8px 30px rgba(0,0,0,0.12)" }}
              transition={{ duration: 0.3 }}
            >

              <form id="contact-form" onSubmit={handleSubmit} className="space-y-6">
                <div className="relative">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="peer w-full px-3 py-3 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500 transition-colors"
                    placeholder="Your Name"
                    required
                  />
                  <label 
                    htmlFor="name" 
                    className="absolute left-3 -top-5 text-sm text-gray-600 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Your Name
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="peer w-full px-3 py-3 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500 transition-colors"
                    placeholder="Your Email"
                    required
                  />
                  <label 
                    htmlFor="email" 
                    className="absolute left-3 -top-5 text-sm text-gray-600 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Your Email
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="peer w-full px-3 py-3 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500 transition-colors"
                    placeholder="WhatsApp/Phone Number"
                    required
                  />
                  <label 
                    htmlFor="phone" 
                    className="absolute left-3 -top-5 text-sm text-gray-600 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    WhatsApp/Phone Number
                  </label>
                </div>
                <div className="relative">
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className="peer w-full px-3 py-3 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500 transition-colors resize-none"
                    placeholder="Your Message"
                    required
                  ></textarea>
                  <label 
                    htmlFor="message" 
                    className="absolute left-3 -top-5 text-sm text-gray-600 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Your Message
                  </label>
                </div>
                <motion.button 
                  type="submit" 
                  className="w-full bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out flex items-center justify-center group hover:from-cyan-600 hover:to-blue-600"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Send className="mr-2 transition-transform duration-300 group-hover:translate-x-1" size={20} />
                  Send Message
                </motion.button>
              </form>
              {formStatus && (
                <motion.p 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-4 text-green-600 text-center"
                >
                  {formStatus}
                </motion.p>
              )}
            </motion.div>

            {/* Contact Information */}
            <motion.div 
              className="flex-1 bg-white rounded-lg shadow-xl p-8 border border-gray-200"
              whileHover={{ boxShadow: "0 8px 30px rgba(0,0,0,0.12)" }}
              transition={{ duration: 0.3 }}
            >
              <div className="space-y-6">
                <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg">
                  <div className="p-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full text-white">
                    <Mail size={24} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Email</p>
                    <a href="mailto:info@buildfast.co.zw" className="text-gray-900 hover:text-blue-500 transition-colors">
                      info@buildfast.co.zw
                    </a>
                  </div>
                </div>
                <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg">
                  <div className="p-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full text-white">
                    <Phone size={24} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Phone</p>
                    <a href="tel:+263776347735" className="text-gray-900 hover:text-blue-500 transition-colors">
                      +263 776 347 735
                    </a>
                  </div>
                </div>
                <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg">
                  <div className="p-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full text-white">
                    <MapPin size={24} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Address</p>
                    <p className="text-gray-900">Margoliz Plaza Cnr Harare st & Speke Ave, Harare</p>
                  </div>
                </div>
                <div className="mt-8 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg">
                  <h4 className="text-xl font-semibold mb-4 text-gray-900">Office Hours</h4>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center">
                      <span className="w-4 h-4 bg-green-500 rounded-full mr-2"></span>
                      Monday - Friday: 9:00 AM - 5:00 PM
                    </li>
                    <li className="flex items-center">
                      <span className="w-4 h-4 bg-yellow-500 rounded-full mr-2"></span>
                      Saturday: 10:00 AM - 2:00 PM
                    </li>
                    <li className="flex items-center">
                      <span className="w-4 h-4 bg-red-500 rounded-full mr-2"></span>
                      Sunday: Closed
                    </li>
                  </ul>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </section>
    </main>
  );
};

export default Home;
